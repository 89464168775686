:root {
  --primary-blue: #2970a4;
  --secondary-blue: #3b9ce2;
  --bullet-point-font: Helvetica, Arial, sans-serif;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  text-align: right;
  padding: 60px 0 50px 0;
  /* display: block; */
}

.full-viewport-section {
  /* height: 100vh; */
  min-height: 100vh;
  /* Other styles as needed, e.g., padding, background color, etc. */
}

.header {
  display: flex;
  justify-content: right;
  /* justify-content: center; */
  align-items: center;
  /* text-align: right; */
  width: 80%;
  margin: 0 auto;

}

.container {
  display: flex;
  /* position: relative; */
  justify-content: center;
  align-items: center;
  /* text-align: right; */
  width: 80%;
  margin: 0 auto;
}

.horizontal-list {
  display: flex;
  justify-content: right;
  align-items: center;
  list-style-type: none;
  padding: 10px 0px 10px 10px;
  /* padding-right: 5%; */
  list-style: none;
  width: 80%;
}

.horizontal-list li a {
  margin-left: 20px;
  /* Adjust this value as per your design */
  /* font-size: large; */
  color: var(--primary-blue);
  font-weight: 700;
  transition: 200ms ease;
  font-size: 24px;
}

.nav-btn{
  cursor: pointer;
  margin: 0 0;
}

.nav-btn:hover {
  color: var(--secondary-blue);
}

.contact-btn {
  color: #35323E !important;
  background: transparent;
  background-color: transparent;
  /* font-weight: 700;
    font-size: 16px; */
  font-weight: 700;
  padding: 12px 25px;
  border-radius: 5px;
  font-size: 28px !important;
  border: 4px solid #35323E;
  /* -webkit-transition: 0.3s ease-in !important; */
  transition: 0.3s ease-in !important;
  display: inline-block;
  position: relative;
}

.contact-btn:hover{
  background-color: lightgray;
}

.contact-btn-fill {
  color: white;
  background: transparent;
  background-color: #35323E;
  /* font-weight: 700;
    font-size: 16px; */
  font-weight: 700;
  padding: 12px 25px;
  border-radius: 5px;
  font-size: 28px !important;
  border: 4px solid #35323E;
  /* -webkit-transition: 0.3s ease-in !important; */
  transition: 0.3s ease-in !important;
  display: inline-block;
  position: relative;
}

.contact-btn-fill:hover{
  background-color: #645d74;
}

.landing {
  /* padding: 0 0 60px 0; */
  /* min-height: 100vh; */
  background: linear-gradient(-0deg, white, #e6e6e6);
  /* background-size: 400% 400%; */
  /* -webkit-animation: gradient 10s ease infinite; */
  /* animation: gradient 120s linear infinite; */
  /* opacity: 0.4; */
  /* background-image: url(./Images/subtle.avif); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 10%;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

@keyframes gradient {
  100% {
    background-position: 100% 0;
  }

  0% {
    background-position: 0 0;
  }
}
.row {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around !important; 
}
.row .col {
  /* -webkit-box-flex: 50%;
  -ms-flex: 50%;*/
  /* flex: 1 0 50%; */
  flex: 1 1 auto;
  position: relative;
  min-width: 300px;
  text-align: right;
  /* display: flex; */
  
}

@media (max-width: 768px) {
  .row .col {
    flex-basis: 100%; /* Take up the full width on small screens */
  }
  #illustration-landing {
    height: auto; /* Adjust the image height */
  }
}

#illustration-landing {
  max-width: 100%;
  height: auto;
}


.landing-description {
  color: grey;
  font-size: 20px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
  left: 50%;
}

/* .landing .row {
  display: table
} */

.landing #col-left {
  order: 0;
  /* display: table-cell; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  width: 50%;
  text-align: left;
}

#illustration-landing {
  height: 500px;
  /* justify-content: end; */
  /* transform: translateX(-50%); */
  border-radius: 10px;
  order: 1;
  /* flex-wrap: wrap; */
  flex: 1 1 auto;
}

.section-title {
  font-size: 64px;
  /* margin-left: 9%; */
  margin: 0% 0 4% 9%;
  padding-top: 60px;
  color: #35323E;
  font-weight: 600;
  margin-bottom: 50px;
  text-shadow: 1px 0 2px #2970a4;
  text-align: left;
}

.section-title:after {
  content: "";
  display: block;
  border-bottom: 5px solid var(--secondary-blue);
  width: 125px;
  position: relative;
  bottom: -10px;
}

.landing-title {
  font-size: 80px;
  color: #35323E;
  /* margin-bottom: 20px; */
  font-weight: 700;
}

.landing-title span {
  color: var(--secondary-blue);
}

a {
  text-decoration: none;
  /* color: black; */
}

h1 {
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-rendering: optimizelegibility;
  margin-top: 0;
}

p {
  margin-bottom: 20px;
  /* color: #848486; */
  color: black;
  line-height: 1.6em;
  font-size: 18px;
  font-family: "Roboto", sans-serif !important;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.experience-section {
  /* padding-top: 1px; */
  display: flex;
  flex: 1 1 auto;
  align-items: start;
  flex-direction: column;
}

.experience-container {
  display: flex;
  flex-direction: row;
  align-items: top;
  width: 80%; /* Adjust as needed */
  margin: auto; /* Center horizontally */

}

.left-toolbar {
  width: 250px;
  /* margin-right: 30px; */
  /* padding-right: 50px; */
  text-align: start;
}

.left-toolbar ul {
  list-style: none;
  padding-left: 0;
  width: 200px;
}

.selected-item {
  color: var(--primary-blue);
}

.left-toolbar li {
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  padding: 20px 0 20px 20px;
  font-size: 24px;
  font-family: var(--bullet-point-font);
}

.active-bar,
.inactive-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px; /* Adjust as needed */
}

.active-bar {
  background-color: var(--primary-blue); /* Active color, adjust as needed */
}

.inactive-bar {
  background-color: #cccccc; /* Inactive color, adjust as needed */
  transition: background-color 0.3s ease; /* Transition effect */
}

.left-toolbar li:hover .inactive-bar {
  background-color: #999999; /* Hover color, adjust as needed */
}




.right-description {
  flex: 1 1 0;
  text-align: left; /* Align text to the left */
  margin-left: 50px;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
}

.title-dates {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.title-dates h2{
  color: var(--primary-blue);
  font-size: 36px;
}

.title-dates p{
 margin: 0;
 font-size: larger;
}

.company-logo {
  align-self: flex-start; /* Align logo to the top */
    width: 100px; /* Set the width of the image */
    height: 100px; /* Set the height of the image */
    border-radius: 20%; /* Make the image circular */
    object-fit:scale-down; /* Ensure that the image covers the entire element without distorting its proportions */
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2); /* Add shadow */
}

.description-bullets {
  margin-left: 20px; /* Indentation for bullets */
  font-size: 20px;
  font-family: var(--bullet-point-font);
  /* font-family: 'Courier New', Courier, monospace; */
}

.description-bullets li {
  padding: 0px 0 40px 0; 
}

.technologies {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; /* Change the font */
}

.technologies-list {
  margin-top: 10px;
  display: flex;
  justify-content:start; /* Evenly space the items */
  flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.technology-item {
  margin: 0 20px 0 0; /* Spacing between items */
}

.about-section {
  height: 100vh !important; 
}

.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%; /* Adjust as needed */
  height: 75%;
  margin: auto;
}

.drawing,
.social-btns {
  flex: 1; /* This will make both elements take up equal width */
  display: flex; /* Added to align the content within */
  justify-content: start; /* Center content horizontally */

}

.drawing img {
  width: auto;
  max-width: 600px;
  border-radius: 10px;
}

.social-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: left; */
}

.social-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  /* background-color: black; */
  color: #ffffff;
  /* color: black; */
  border-radius: 50%;
  width: 75px; /* Adjust as needed */
  height: 75px; /* Adjust as needed */
  /* text-align: center; */
  display: block;
  text-decoration: none;
  margin: 33px; /* Space between buttons */
  transition: 0.3s;
}

.social-btn:hover {
  background-color: lightgray;
}


